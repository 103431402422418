<template>
  <b-card>
    <b-table
      ref="table"
      show-empty
      empty-text="There is no Live!"
      :items="getLiveList"
      :fields="columns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      aria-busy="true"
      class="position-relative"
      responsive
      primary-key="id"
      :no-provider-sorting="true"
    >
      <template #cell(session_link)="{item}">
        <b-link
          :href="item.session_link"
          target="_blank"
        >
          Go To Live
        </b-link>
      </template>
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="$can('show','global-LiveController')"
            :to="{ name: 'live-view', params: { id: item.id } }"
          >
            <feather-icon icon="FileTextIcon" />
            <span> View </span>
          </b-dropdown-item>

          <share-deep-link
            :id="item.session_id"
            route="/Webinar"
          />

          <b-dropdown-item
            v-if="$can('show','global-PollController')"
            :to="{ name: 'polls-list',params: { activityId:item.id ,liveId: item.session_id} }"
          >
            <feather-icon icon="HelpCircleIcon" />
            <span> Poll Management </span>
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'live-comments',params: { id: item.id, sessionId:item.session_id} }">
            <feather-icon icon="MessageSquareIcon" />
            <span> Comments </span>
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'live-comments-reports',params: { id: item.session_id} }">
            <feather-icon icon="FlagIcon" />
            <span> Reported Comments </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('update','global-LiveController') && $moment().isBefore(item.session_end)"
            :to="{ name: 'edit-live', params: { id: item.id } }"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('destroy','global-LiveController')"
            @click="deleteRecord(deleteEndPoint,item.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import deleteEntityComposition from '@/common/compositions/common/deleteRecord'
import ShareDeepLink from '../common/Table/ShareDeepLink.vue'

export default {
  name: 'LiveListTable',
  components: {
    ShareDeepLink,
  },
  props: {
    pagination: { type: Object, default: () => {} },
    getLiveList: { type: Function, default: () => null },
  },
  setup() {
    const { deleteRecord, table } = deleteEntityComposition()

    return {
      deleteRecord,
      table,
    }
  },
  data() {
    return {
      columns: [
        { key: 'activity_name', label: 'NAME', sortable: true },
        { key: 'speaker' },
        { key: 'session_start', label: 'START DATE/TIME', sortable: true },
        { key: 'session_end', label: 'END DATE/TIME', sortable: true },
        { key: 'session_link', label: 'Session Link', sortable: true },
        { key: 'session_to_be_raised', label: 'To Be Raised', sortable: true },
        { key: 'entity_id.name', label: 'Entity Name', sortable: true },
        { key: 'polls_count', sortable: true },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/live/`,
    }
  },
}
</script>

<style scoped>

</style>
