<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="global-LiveController"
        add-route-name="add-live"
      />
    </div>

    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col
              md="6"
              class="mb-1 mb-md-0"
            >
              <search
                placeholder="Name"
                :search-query.sync="searchQuery.name"
                @refresh="refreshTable"
              />
            </b-col>
            <b-col md="6">
              <inpo-select :inpo-id.sync="entityId" />
            </b-col>
            <b-col md="6">
              <custom-vue-select
                id="live-status-search"
                placeholder="Select live status"
                :value-reducer="option=>option.value"
                :options="[
                  {text:'All',value:null},
                  {text:'Current',value:'current'},
                  {text:'Outdate',value:'outdate'},
                  {text:'Coming',value:'coming'},
                ]"
                :selected.sync="searchQuery.live_status"
                text-field="text"
              />
            </b-col>
            <b-col md="6">
              <date-range-filter
                :date-range.sync="searchQuery.range"
              />
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-row align-h="end">
                <b-col
                  md="2"
                  class="mt-1 mt-md-0"
                >
                  <search-button @refresh="refreshTable" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <live-list-table
      ref="parentTableComponent"
      :pagination="pagination"
      v-bind="{
        getLiveList
      }"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

  </div>
</template>

<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import InpoSelect from '@/common/components/common/FormInputs/InpoSelect.vue'
import LiveListTable from '@/common/components/LiveManagement/LiveListTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import paginationData from '@/common/compositions/common/paginationData'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'LiveList',
  components: {
    AddResource,
    Search,
    SearchButton,
    LiveListTable,
    Pagination,
    InpoSelect,
    DateRangeFilter,
    CustomVueSelect,
  },
  data() {
    return {
      searchQuery: { live_status: null, range: [] },
      entityId: '',
      loading: true,
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    refreshTable() {
      this.$refs.parentTableComponent.$refs.table.refresh()
    },
    getLiveList() {
      const promise = this.$activities.get(`internalops/live/list/${this.pagination.currentPage}/${this.pagination.perPage}`, {
        params: {
          name: this.searchQuery.name,
          live_status: this.searchQuery.live_status,
          fromDate: this.searchQuery.range[0],
          toDate: this.searchQuery.range[1] === this.searchQuery.range[0] ? null : this.searchQuery.range[1],
          entity: this.entityId,
          'with-reactions': false,
        },
      })
      return promise.then(res => {
        const liveList = res.data.data.data

        this.pagination.totalRows = res.data.data.pagination.total
        return liveList || []
      }).finally(() => { this.loading = false })
    },
  },
}

</script>

<style lang="scss">

</style>
